<template>
    <div id="WaterElectricityMeterConsume">
        <div class="tab-bar">
            <div
                v-for="item in routeViews"
                :key="item.routerName"
                @click="changeTabBar(item.routeName)"
                :class="{ active: item.routeName == $route.name }"
            >
                {{ item.name }}
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
import {STORAGE_KEY} from "@/constant";

export default {
    name: 'WaterElectricityMeterConsume',
    data() {
        return {
            routeViews: [
                { name: "水表", routeName: "waterMeterConsume" },
                { name: "电表", routeName: "waterElectricityConsume" },
            ],
        }
    },
    mounted() {
    },
    methods: {
        changeTabBar(routerName) {
            sessionStorage.removeItem(STORAGE_KEY.ROUTE_HISTORY);
            this.$router.push({ name: routerName });
        },
    }
}
</script>

<style scoped>
.tab-bar div {
    border-radius: 4px;
}
.tab-bar > div:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.tab-bar > div:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>

